import { Route, Routes, Navigate } from 'react-router-dom';
import Join from './pages/Join';
import SubscribeInfo from './pages/SubscribeInfo';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/join" />} />
      <Route path="/join" element={<Join />} />
      <Route path="/subscribeinfo" element={<SubscribeInfo />} />
    </Routes>
  );
}

export default App;
