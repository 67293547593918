import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Stack,
  Flex,
  InputGroup,
  useToast,
} from '@chakra-ui/react';
import {
  validateName,
  validateEmail,
  validatePhoneNumber,
  validateRecommenderPhoneNumber,
  validateTelegramId,
  validateVerificationCode,
} from '../../hooks/useValidation';
import UpmAgreement from './UpmAgreement';
import {
  requestRecord,
  requestVerificationCode,
  requestVerification,
} from '../../hooks/useRequest';

const UpmJoinForm = () => {
  let navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verified, setVerified] = useState(false);
  const toast = useToast();
  const showToast = (status, title, description) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleOnChangeInput = event => {
    if (event.target.name === 'phoneNumber') {
      setPhoneNumber(event.target.value);
    } else if (event.target.name === 'verification') {
      setVerificationCode(event.target.value);
    }
  };

  const handleOnClickRequestVerificationCode = event => {
    requestVerificationCode(phoneNumber);
  };

  const handleOnClickVerification = async event => {
    const data = await requestVerification(phoneNumber, verificationCode);
    if (data === 'ok') {
      setVerified(true);
      showToast('success', '성공', '인증완료');
    } else if (data === 'fail - time passed') {
      showToast('error', '실패', 'Timeout');
    } else if (data === 'fail - not match') {
      showToast('error', '실패', '일치하는 정보없음');
    } else if (data === 'fail - no data') {
      showToast('error', '실패', '데이터 없음');
    }
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        telegramId: '',
        phoneNumber: '',
        verified: '',
        recommenderPhoneNumber: '',
      }}
      onSubmit={async (values, actions) => {
        // toast component 를 return 시도해보기
        const data = await requestRecord(values);
        console.log(data);
        console.log(verified);
        if (data === 'ok' && verified === true) {
          navigate('/subscribeinfo');
        } else if (verified === false) {
          showToast('error', '실패', '휴대폰 인증이 필요합니다');
        }
        actions.setSubmitting(false);
      }}
    >
      {props => (
        <Form onChange={handleOnChangeInput}>
          <Field name="name" validate={validateName}>
            {({ field, form }) => (
              <FormControl
                isRequired
                isInvalid={form.errors.name && form.touched.name}
              >
                <FormLabel>실명</FormLabel>
                <Input {...field} placeholder="이름" mb={8} />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="email" validate={validateEmail}>
            {({ field, form }) => (
              <FormControl
                isRequired
                isInvalid={form.errors.email && form.touched.email}
              >
                <FormLabel>이메일</FormLabel>
                <Input {...field} placeholder="이메일" mb={8} />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="telegramId" validate={validateTelegramId}>
            {({ field, form }) => (
              <FormControl
                isRequired
                isInvalid={form.errors.telegramId && form.touched.telegramId}
              >
                <FormLabel>텔레그램 ID (예:홍추세1234)</FormLabel>
                <Input {...field} placeholder="텔레그램 ID" mb={8} />
                <FormErrorMessage>{form.errors.telegramId}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="phoneNumber" validate={validatePhoneNumber}>
            {({ field, form }) => (
              <FormControl
                isRequired
                isInvalid={form.errors.phoneNumber && form.touched.phoneNumber}
              >
                <FormLabel>휴대폰번호</FormLabel>
                <InputGroup>
                  <Input
                    {...field}
                    placeholder="전화번호"
                    mb={8}
                    mr={2}
                    value={phoneNumber}
                  />
                  <Button onClick={handleOnClickRequestVerificationCode}>
                    인증번호 요청
                  </Button>
                </InputGroup>

                <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="verification" validate={validateVerificationCode}>
            {({ field, form }) => (
              <FormControl
                isRequired
                isInvalid={
                  form.errors.verification && form.touched.verification
                }
              >
                <FormLabel>인증번호</FormLabel>
                <InputGroup>
                  <Input
                    {...field}
                    placeholder="인증번호 입력"
                    mb={8}
                    mr={2}
                    value={verificationCode}
                  />
                  <Button onClick={handleOnClickVerification}>인증</Button>
                </InputGroup>

                <FormErrorMessage>{form.errors.verification}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field
            name="recommenderPhoneNumber"
            // validate={validateRecommenderPhoneNumber}
          >
            {({ field, form }) => (
              <FormControl
              // isInvalid={
              //   form.errors.recommenderPhoneNumber &&
              //   form.touched.recommenderPhoneNumber
              // }
              >
                <FormLabel>추천인 휴대폰번호</FormLabel>
                <Input {...field} placeholder="Phone Number" mb={8} />
                <FormErrorMessage>
                  {form.errors.recommenderPhoneNumber}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <FormControl isRequired as="fieldset">
            <Stack spacing={5} direction="column" my={5}>
              <Flex mx="auto" me="60px">
                <UpmAgreement agree_type="privacy" />
              </Flex>
              <Flex mx="auto" me="60px">
                <UpmAgreement agree_type="regulation" />
              </Flex>
            </Stack>
          </FormControl>
          <Button
            mt={4}
            colorScheme="teal"
            isLoading={props.isSubmitting}
            type="submit"
          >
            제출
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default UpmJoinForm;
