import { ChakraProvider, VStack, Flex, theme } from '@chakra-ui/react';
import UpmHeader from '../components/UpmHeader';
import UpmJoinForm from '../components/form/UpmJoinForm';

const Join = () => {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        mt="50px"
        mb="50px"
        align="center"
        justify="center"
        textAlign="center"
        fontSize="xl"
      >
        <VStack w="800px" spacing="40px">
          <UpmHeader titleMessage={'가입 신청서'} />
          {/* <UpmFormContainer /> */}
          <UpmJoinForm />
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default Join;
