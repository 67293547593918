import { SimpleGrid } from '@chakra-ui/react';
import UpmItem from './UpmItem';
import { cardData } from '../constants/item';

const UpmItemContainer = () => {
  const cardList = cardData.map(data => (
    <UpmItem
      key={data.id}
      headerText={data.headerText}
      bodyText={data.bodyText}
      footerText={data.footerText}
    />
  ));

  return (
    <SimpleGrid columns={3} spacing={4}>
      {cardList}
    </SimpleGrid>
  );
};

export default UpmItemContainer;
