import {
  Checkbox,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';
import { agreements } from '../../constants/agreements';

const UpmAgreement = props => {
  const { agree_type } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkbox =
    agree_type === 'privacy'
      ? agreements.privacy.checkbox
      : agreements.regulation.checkbox;

  const title =
    agree_type === 'privacy'
      ? agreements.privacy.title
      : agreements.regulation.title;

  const get_body = () => {
    const body =
      agree_type === 'privacy'
        ? agreements.privacy.body
        : agreements.regulation.body;

    const bodyText = body;
    return (
      <>
        {bodyText.map(item => {
          return (
            <Box ms="auto" my={2} key={item.id}>
              {item.text}
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Button onClick={onOpen}>내용확인</Button>
      <Checkbox mx={5}> {checkbox} </Checkbox>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{get_body()}</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              확인
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpmAgreement;
