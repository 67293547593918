import { VStack, Button, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { GUIDE_BOT } from '../config/url';

const UpmPaymentInfo = () => {
  return (
    <VStack spacing="30px">
      <List
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        spacing={3}
      >
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          [은행 및 예금주] 하나은행 더원프로젝트
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          {/* 카피버튼 넣기 */}
          [계좌번호] 742-910011-94604
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          입금일 / 입금자명 / 전화번호 뒷자리를 반드시 아래 예시와 같이
          기재해주시기 바랍니다.
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          예시) 12/10 홍추세 1234
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          입금이 완료되면 하단의 '신청하기' 버튼을 눌러주세요.
        </ListItem>
      </List>
      <Button as="a" href={GUIDE_BOT} colorScheme="teal">
        신청하기
      </Button>
    </VStack>
  );
};
export default UpmPaymentInfo;
