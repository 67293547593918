export const cardData = [
  {
    id: 1,
    headerText: '시황폴더',
    bodyText:
      '뛰어난 통찰력으로 현재 시장 상황을 이해할 수 있는 정보를 제공 받습니다',
    footerText: '구독료 : 27,000원',
  },
  {
    id: 2,
    headerText: '매매폴더',
    bodyText:
      '본사의 알고리즘에 검색된 상승 가능한 종목의 가격 정보를 제공 받습니다',
    footerText: '구독료 : 57,000원',
  },
  {
    id: 3,
    headerText: '종목폴더',
    bodyText: '삼성전자, 에코프로비엠등 개별 종목에 관한 정보를 제공 받습니다',
    footerText: '구독료 : 12,000원',
  },
];
