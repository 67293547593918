import axios from 'axios';
import { SERVER } from '../config/url';

export const requestVerificationCode = phoneNumber => {
  let body = {
    phone_num: phoneNumber,
  };
  axios
    .post(SERVER + 'application_form/send_sms', body)
    .then(response => {
      // TODO 요청이 실패했을경우 toast 알림
      console.log(response);
    })
    .catch(error => {
      console.error(error);
    });
};

export const requestVerification = (phoneNumber, verificationCode) => {
  let data;
  let body = {
    phone_num: phoneNumber,
    code: verificationCode,
  };

  data = axios
    .post(SERVER + 'application_form/verify', body)
    .then(response => {
      return response.data.status;
    })
    .catch(error => {
      console.error(error);
    });
  return data;
};

// requestRecord
export const requestRecord = values => {
  let data;
  let body = {
    name: values['name'],
    email: values['email'],
    telegramId: values['telegramId'],
    phoneNumber: values['phoneNumber'],
    recommenderPhoneNumber: values['recommenderPhoneNumber']
      ? values['recommenderPhoneNumber']
      : '',
  };
  data = axios
    .post(SERVER + 'application_form/add_user', body)
    .then(response => {
      return response.data.status;
    })
    .catch(error => {
      console.error(error);
    });
  return data;
};
