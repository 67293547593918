import {
  Text,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@chakra-ui/react';

const UpmItem = props => {
  const headerText = props.headerText;
  const bodyText = props.bodyText;
  const footerText = props.footerText;
  return (
    <Card>
      <CardHeader>
        <Heading size="md">{headerText}</Heading>
      </CardHeader>
      <CardBody>
        <Text>{bodyText}</Text>
      </CardBody>
      <CardFooter>
        <Text>{footerText}</Text>
      </CardFooter>
    </Card>
  );
};
export default UpmItem;
