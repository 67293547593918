export const validateName = value => {
  let error;
  if (!value) {
    error = '이름을 입력해야 합니다';
  }
  return error;
};

export const validateEmail = value => {
  let error;
  if (!value) {
    error = '이메일을 입력해야 합니다';
  }
  return error;
};

export const validateTelegramId = value => {
  let error;
  if (!value) {
    error = '텔레그램 아이디를 입력해야 합니다';
  }
  return error;
};

export const validatePhoneNumber = value => {
  let error;
  if (!value) {
    error = '휴대폰번호를 입력해야 합니다';
  }
  return error;
};

export const validateVerificationCode = value => {
  let error;
  if (!value) {
    error = '인증번호를 입력해야 합니다';
  }
  return error;
};

export const validateRecommenderPhoneNumber = value => {
  let error;
  if (!value) {
    error = '추천인 번호가 정확하지 않습니다.';
  }
  return error;
};
