import { ChakraProvider, VStack, theme, Flex, Divider } from '@chakra-ui/react';

import UpmHeader from '../components/UpmHeader';
import UpmItemContainer from '../components/UpmItemContainer';
import UpmPaymentInfo from '../components/UpmPaymentInfo';

const SubscribeInfo = () => {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        mt="50px"
        mb="50px"
        align="center"
        justify="center"
        textAlign="center"
        fontSize="xl"
      >
        <VStack w="800px" spacing="40px">
          <UpmHeader titleMessage={'구독 안내'} />
          <UpmItemContainer />
          <Divider />
          <UpmPaymentInfo />
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default SubscribeInfo;
