export const agreements = {
  privacy: {
    checkbox: '개인 정보 수집에 동의합니다',
    title: '개인정보 수집 및 이용 동의서 ',
    body: [
      {
        id: 1,
        text: '1. 수집하는 개인 정보 항목 : 이름, 텔레그램 이름, 이메일, 전화번호 등',
      },
      {
        id: 2,
        text: '- 텔레그램 닉네임은 반드시 성함(전화번호 뒷자리)로 입력해주셔야 합니다.           ex) 홍길동(1234)',
      },
      {
        id: 3,
        text: '- 양식에 어긋나거나 허위정보의 경우, 결제 후에도 구독이 불가할 수 있습니다.',
      },
      {
        id: 4,
        text: '2. 개인정보의 수집 및 이용 목적 : 제공하신 정보는 채널구독 가입 및 운영을 위해 사용합니다.',
      },
      {
        id: 5,
        text: '3. 개인정보의 보유 및 이용 기간 : 수집된 개인정보의 보유 기간은 시황채널 서비스 유효기간 및 종료 후 1년간이며, 삭제 요청 시 당사자는 개인 정보를 재생이 불가능한 방법으로 즉시 파기합니다.',
      },
      { id: 6, text: '4. *귀하는 이에 대한 동의를 거부 할 수 있습니다.' },
      {
        id: 7,
        text: '- 동의가 없을 경우 당 서비스 이용이 불가능 할 수 있음을 알려드립니다.',
      },
    ],
  },
  regulation: {
    checkbox: '구독 규정에 동의합니다',
    title: '구독 규정 숙지 및 동의서',
    body: [
      {
        id: 1,
        text: '- 유료 구독 서비스 기간 중 언제든지 구독을 취소할 수 있습니다.',
      },
      {
        id: 2,
        text: '- 구독을 취소해도 한달동안 서비스는 제공되며 구독 요금은 환불되지 않습니다.',
      },
      {
        id: 3,
        text: '* 귀하는 이에 대한 동의를 거부 할 수 있습니다.',
      },
      {
        id: 4,
        text: '다만 동의가 없을 경우 해당 서비스 이용이 불가능 할 수 있음을 알려드립니다',
      },
    ],
  },
};
